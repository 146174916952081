<template>
  <BaseModal
    name="create-new-pixel"
    id="create-new-pixel"
    idModal="create-new-pixel"
    size="lg"
    title="Novo Pixel"
    @shown="openModal()"
  >
    <div class="container-grid" v-if="!loading">
      <!-- Tipo -->
      <div id="select-type-pixel">
        <label for="pixel_type">Selecione o tipo do pixel</label>
        <multiselect
          v-model="selected_type_pixel"
          id="pixel_type"
          label="name"
          track-by="id"
          placeholder="Pesquise por um pixel"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          open-direction="bottom"
          :options="types"
          :multiple="false"
          :taggable="false"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="true"
          :show-no-results="true"
          :hide-selected="false"
        >
          <span slot="noOptions">Pesquise por um pixel</span>
          <span slot="noResult">Oops! Nenhum pixel encontrado.</span>
        </multiselect>
      </div>
      <!-- Titulo -->
      <div>
        <label for="pixel_title">Título</label>
        <input
          style="width: 100%"
          type="text"
          id="pixel_title"
          v-model="title"
        />
      </div>
      <!-- Pixel ID -->
      <div>
        <label for="pixel_id">Pixel ID</label>
        <input
          style="width: 100%"
          type="text"
          id="pixel_id"
          v-model="pixel_id"
        />
      </div>

      <!-- <div v-if="selected_type_pixel.type == 'GOOGLEADWORDS'">
        <label for="pixel_id">Label de conversão (opcional)</label>
        <input
          style="width: 100%"
          type="text"
          id="pixel_id"
          v-model="conversion_label"
          placeholder="Label de conversão (opcional)"
        />
      </div> -->

      <div v-if="selected_type_pixel.type == 'FACEBOOK'">
        <!-- Teste pixel -->
        <div>
          <label for="pixel_test">Teste pixel</label>
          <input
            style="width: 100%"
            type="text"
            id="pixel_test"
            v-model="pixel_test"
          />
        </div>
        <small
          >No final da url da página adicione
          <strong data-v-18b59dfe="">?test_event=teste</strong>, para que o
          facebook possa localizar o evento cadastrado.
        </small>
      </div>

      <!-- Selects -->
      <div>
        <!-- Mensagem -->

        <!-- Visualização -->
        <b-form-checkbox
          id="checkbox-Visualização"
          name="checkbox-1"
          value="accepted"
          v-model="visualizacao"
        >
          Eventos de visualização
        </b-form-checkbox>
        <!-- Conversão -->
        <b-form-checkbox
          id="checkbox-Conversão"
          name="checkbox-1"
          value="accepted"
          v-model="conversao"
        >
          Eventos de conversão
        </b-form-checkbox>
        <div v-if="selected_type_pixel.type == 'FACEBOOK'">
          <!-- Navegador -->
          <b-form-checkbox
            id="checkbox-Navegador"
            name="checkbox-1"
            value="accepted"
            unchecked-value="not_accepted"
            v-model="navegar"
          >
            <div style="display: grid">
              Navegador
              <small>Dados serão salvos direto do navegador do usuário</small>
            </div>
          </b-form-checkbox>
          <!-- Conversão API -->
          <b-form-checkbox
            id="checkbox-api"
            name="checkbox-1"
            value="accepted"
            unchecked-value="not_accepted"
            v-model="api_conversao"
          >
            <div style="display: grid">
              Api de conversão
              <small> Dados serão enviados para a API de conversão </small>
            </div>
          </b-form-checkbox>
          <!-- Token -->
          <div v-if="api_conversao">
            <label for="token_api">Token api de conversão</label>
            <input
              style="width: 100%"
              type="text"
              name="token_api"
              id="token_api"
              v-model="token_api"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- SE EXISTIR LOADING-->
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit"> Salvar </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import PixelService from "@/services/resources/PixelService";
const servicePixel = PixelService.build();
//
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      token_api: "",
      api_conversao: false,
      navegar: false,
      conversao: false,
      visualizacao: true,
      pixel_test: "",
      pixel_id: "",
      title: "",
      selected_type_pixel: { id: 1, name: "Facebook", type: "FACEBOOK" },
      types: [
        { id: 1, name: "Facebook", type: "FACEBOOK" },
        { id: 2, name: "Google Ads", type: "GOOGLEADWORDS" },
        { id: 3, name: "Google Tag Manager", type: "GOOGLETAGMANAGER" },
        { id: 4, name: "Google Analytics", type: "GOOGLEANALYTICS" },
        { id: 5, name: "Tiktok", type: "TIKTOK" },
      ],
      loading: false,
      conversion_label: "",
    };
  },
  methods: {
    onSubmit() {
      if(this.pixel_id.match(/<\/?[a-z][\s\S]*>/i)){
        this.$grToast.toast("Insira apenas o id do pixel", {
            title: "Pixel",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          return;
      }
      this.loading = true;
      
      var data = {
        pixel_id: this.pixel_id,
        type: this.selected_type_pixel.type,
        title: this.title,
        view: this.visualizacao ? "1" : "0",
        conversion: this.conversao ? "1" : "0",
        web: this.navegar ? "1" : "0",
        api: this.api_conversao ? "1" : "0",
        token: this.token_api,
        test_event_code_pixel: this.pixel_test,
        conversion_label: this.conversion_label,
      };
      servicePixel
        .create(data)
        .then((resp) => {
          this.$grToast.toast("Pixel salvo com sucesso", {
            title: "Pixel",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          (this.token_api = ""),
            (this.api_conversao = false),
            (this.navegar = false),
            (this.conversao = false),
            (this.visualizacao = false),
            (this.pixel_test = ""),
            (this.pixel_id = ""),
            (this.title = ""),
            this.$parent.getPixels();
          this.$bvModal.hide("create-new-pixel");
        })
        .catch((error) => {
          // console.log(error);
          this.$grToast.toast("Erro ao criar pixel", {
            title: "Pixel",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openModal(){
      this.token_api =  "";
      this.api_conversao =  false;
      this.navegar =  false;
      this.conversao =  false;
      this.visualizacao =  true;
      this.pixel_test =  "";
      this.pixel_id =  "";
      this.title =  "";
      this.selected_type_pixel =  { id: 1, name: "Facebook", type: "FACEBOOK" };
    }
  },
};
</script>

<style scoped langue="scss">
.container-grid {
  display: grid;
  gap: 5px;
}
</style>
